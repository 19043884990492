export function basicTask() {
	const backTop = document.querySelector(".back-to-top");
	const counterItem = document.querySelectorAll(".counter");
	const setBackground = document.querySelectorAll("[setBackground]");
	const menuToggle = document.querySelector(".site-menu-toggle");
	const closeMenu = document.querySelector(".close-menu");
	const searchToggle = document.querySelector(".search-toggle");
	const bodyWrap = document.querySelector("body");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	const searchWrap = document.querySelector(".search-overlay");
	const hamburger = document.querySelector(".hamburger");
	// Card Flex  Hover , click
	const cardHover = $(".card-option");

	cardHover.on("mouseenter", function () {
		$(".card-option").removeClass("active");
		$(this).toggleClass("active");
	});
	//Basic Toggle Function
	menuToggle.addEventListener("click", (e) => {
		e.preventDefault();
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});
	closeMenu.addEventListener("click", () => {
		menuMobile.classList.remove("is-open");
		hamburger.classList.remove("is-active");
		bodyWrap.classList.remove("overlay-bg");
	});

	// Search Toggle
	searchToggle.addEventListener("click", () => {
		searchWrap.classList.toggle("is-open");
		bodyWrap.classList.toggle("overlay-bg");
	});

	//back to top
	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	//Tab function
	//Tab function
	$(".tab-nav a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");
		var tab = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$(this)
			.parents("section")
			.find("." + tab)
			.addClass("active");
	});
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-contact").length) {
			$(".news-contact").insertAfter($(".other-news"));
		}
	}
	if ($(window).width() < 1200.1) {
		$(".drop-menu .title .icon").on("click", function () {
			if ($(this).parents(".drop-menu").hasClass("active")) {
				$(".drop-menu .sub-menu").slideUp();
				$(".drop-menu").removeClass("active");
			} else {
				$(".drop-menu .sub-menu").slideUp();
				$(".drop-menu").removeClass("active");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-menu").addClass("active");
			}
		});
	}

	//Toggle item
	document.querySelectorAll(".toggle-wrap").forEach((wrap, index) => {
		wrap.classList.add("toggle-instance-" + index);

		wrap.querySelectorAll(
			".toggle-instance-" + index + " .toggle-item"
		).forEach((item) => {
			item.addEventListener("click", () => {
				if (item.classList.contains("is-toggle")) {
					item.querySelector(".article").style.display = "none";
					item.classList.remove("is-toggle");
				} else {
					document
						.querySelectorAll(
							".toggle-instance-" + index + " .toggle-item"
						)
						.forEach((otherItem) => {
							otherItem.classList.remove("is-toggle");
							otherItem.querySelector(".article").style.display =
								"none";
						});
					item.querySelector(".article").style.display = "block";
					item.classList.add("is-toggle");
				}
			});
		});
	});

	//setBackground
	setBackground.forEach((attributes) => {
		attributes.style.cssText = `
		background-image: url(${attributes.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
	if (counterItem !== null) {
		if (counterItem.length) {
			const counterUp = window.counterUp.default;
			const callback = (entries) => {
				entries.forEach((entry) => {
					const el = entry.target;
					if (
						entry.isIntersecting &&
						!el.classList.contains("is-visible")
					) {
						counterUp(el, {
							duration: 2000,
							delay: 200,
						});
						el.classList.add("is-visible");
					}
				});
			};
			const IO = new IntersectionObserver(callback, { threshold: 1 });

			counterItem.forEach((counter) => {
				IO.observe(counter);
			});
		}
	}
}
